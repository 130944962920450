* {
  cursor: url(/src/cursor/heart_cursor.png), auto;
}


body {
  background: #fbfbf0;
  background-image: url(/public/images/terrazzo.webp);
  font-family: "Times New Roman", Times, serif;
  font-size: 40px;
  letter-spacing: 0.8px;
  word-spacing: 1.8px;
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  font-style: normal;
  text-transform: none;
}

.h1 {
  text-shadow: 6px 6px 6px #A47899;
  font-family: "Times New Roman", Times, serif;
  font-size: 100px;
  cursor: grab;
  background: #fbfbf0;
  border-radius: 25px;
  animation-name: fadeIn;
  animation-duration: 2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.h1:hover {
  animation-name: bounce;
  animation-duration: 0.7s;
}

@keyframes bounce { 
  0%, 50%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-10px);} 
  60% {transform: translateY(-10px);} 
}

.buffer {
  height: 200px;
}

.card {
  background-color: #fbfbf0;
}


.section-container {
  font-size: 20px;
  padding: 30px;
  border: 10px;
  margin-bottom: 200px;

}

.navbar {
  position: sticky;
}

#projects {
  background-color: bisque;
}

a:hover {
  cursor: pointer;
}

.nav-item {
  margin: 10px;
  font-size: large;
}

.navbar-brand {
  margin-left: 10px;
  font-size: xx-large;
  font-weight: 600;
}

.img-thumbnail {
  height: 100px;
  width: 100px;
  padding: 5px;
  margin: 10px;
}

.img-fluid {
  width: fit-content;
  height: fit-content;
  padding: 10px;
}

.nav-link {
  padding: 10px;
}

.navbar-nav {
  background-color: lavender;
}

.footer-section {
  background-color: lavender;
  font-size: small;
}




